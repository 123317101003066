.home_page_container {
  width: 100%;

  .nav-tabs {
    width: fit-content;
  }

  .tab_content {
    .links_container {
      .nav_title {
        margin-top: -20px;
        margin-bottom: 24px;
        font-size: 0.9em;
      }

      .actions {
        @include flex-position(space-between, center);
        margin-bottom: 40px;

        &.for_extension {
          @include mdScreen {
            flex-direction: column-reverse;
            flex-wrap: wrap;
            align-items: flex-start;

            .btn_secondary {
              margin-bottom: 18px;
              width: 100%;
            }

            .left {
              justify-content: space-between;
              width: 100%;
            }
          }
        }

        .left {
          @include flex-position(flex-start, center);
          gap: 24px;
        }

        .btn {
          padding: 12px 24px;

          @include smScreen {
            padding: 12px 18px;
          }
        }
      }

      .links {
        @include flex-position(center, center);
        flex-direction: column;
        gap: 24px;
        padding: 56px 24px;
        background: $color-white;
        box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
        border-radius: 10px;

        @include mdScreen {
          padding: 56px 12px;
        }

        .link {
          @include flex-position(space-between, center);
          margin: 0 auto;
          padding: 18px 32px;
          width: 100%;
          max-width: 700px;
          background: $color-white;
          border-radius: 12px;
          filter: drop-shadow(0px 7px 30px rgba(0, 0, 0, 0.07));
          border-left: 1px solid #002D2B;

          @include mdScreen {
            padding: 18px 16px;
          }

          a {
            width: fit-content;

            @include mdScreen {
              @include ellipsis;
              max-width: 100%;
            }

            .tags {
              @include flex-position(flex-start, center);
              margin-bottom: 7px;
            }

            .type {
              padding: 2px 6px;
              font-size: 0.6em;
              font-weight: 700;
              letter-spacing: 0.5px;
              width: fit-content;
              border-radius: 0px;

              &.code {
                color: $color-dark-green;
                border: 1px solid $color-dark-green;
              }

              &.link {
                color: $color-primary;
                border: 1px solid $color-primary;
              }
            }

            .expiry {
              margin-left: 12px;
              padding: 1px 6px;
              padding-bottom: 2px;
              font-size: 0.75em;
              letter-spacing: 0.2px;
              width: fit-content;
              color: $color-error;
              background: #ffecec;
              border: 1px solid #ffecec;
              border-radius: 0px;

              span {
                color: $color-error;
                font-weight: 700;
              }

              &.expired {
                font-weight: 500;
                color: $color-text-light;
                background: $color-disabled;
                border: 1px solid $color-disabled;
              }
            }

            h6 {
              margin-bottom: 5px;
              font-size: 1em;
              font-weight: 700;
              color: $color-dark-green;

              @include mdScreen {
                @include ellipsis;
              }
            }

            p {
              margin: 0;
              font-size: 0.9em;
              color: $color-text-light;

              @include mdScreen {
                @include ellipsis;
              }
            }
          }

          .icon_actions {
            @include flex-position(flex-start, center);
            gap: 24px;
            margin-left: 24px;

            @include mdScreen {
              gap: 12px;
              margin-left: 12px;
            }

            .icon {
              @include flex-position(center, center);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}