.success_page {
  @include flex-position(flex-start, flex-start);
  gap: 10px;
  width: 100%;
  height: calc(100vh - 5rem);
  background-color: $color-white;
  padding: 1rem;

  @media screen and (max-width: 450px) {
    padding-left: 18px;
    padding-right: 18px;
  }

  .page_frame {
    @include flex-position(center, center);
    width: 100%;

    @media (max-width: 767px) or (max-height: 670px) {
      width: 100%;
      height: fit-content;
    }

    .page_content {
      width: 100%;
      max-width: 424px;

      .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }

      .loading-spinner {
        border: 4px solid $color-dark-blue;
        border-top: 4px solid transparent;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      @media (max-width: 768px) {
        .loading-container {
          margin-top: 150px;
        }

        .loading-spinner {
          width: 80px;
          height: 80px;
        }
      }

      .header {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 2.3em;
      }

      .title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 40px;

        &.center {
          text-align: center;
        }
      }

      .sub_title {
        font-weight: 500;
        font-size: 18px;
        color: $color-black;

        &.center {
          text-align: center;
        }

        &.middle {
          align-items: center;
          text-align: center;
          flex-direction: column;
          word-wrap: break-word;

          @include mdScreen {
            margin-top: 40px;
          }
        }
      }

      .image_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 20px;

        .profile_picture {
          width: 132px;
          height: 132px;
          border-radius: 50%;
          border: 2.5px solid $color-primary-purple;
        }

        img {
          width: 100px;
          height: 100px;
        }

        @include mdScreen {
          margin-top: 30px;
        }
      }

      .background_blur {
        width: 90%;
        max-width: 675px;
        height: 430px;
        left: 277;
        top: 346;
        position: absolute;
        opacity: 0.20;

        div.ellipse_left {
          width: 100%;
          max-width: 675px;
          height: 203.92px;
          left: 635.94;
          top: 28.81;
          position: absolute;
          opacity: 0.70;
          background: #FFE77B;
          box-shadow: 90px 90px 90px;
          border-radius: 9999;
          filter: blur(90px);
        }

        div.ellipse_right {
          width: 100%;
          max-width: 675px;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          opacity: 0.50;
          background: #00CBC2;
          box-shadow: 250px 250px 250px;
          border-radius: 9999;
          filter: blur(250px)
        }
      }

      .input_container {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid $color-input-border;
        border-radius: 5px;

        &:focus-within {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          outline: 0px auto -webkit-focus-ring-color !important;
          outline: none;
          border: 1px solid $color-primary !important;
          border-radius: 3px;
        }
      }

      input {
        flex: 1;
        border: none;
        padding: 8px;
        font-size: 1em;
        outline: none;
        background-color: transparent;

        &:focus {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          outline: 0px auto -webkit-focus-ring-color !important;
          outline: none !important;
          border: none !important;
          background-color: none;
        }
      }
      
      input::placeholder {
        color: #4F4F4F !important;
      }

      .input_icon {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;
      }

      .left_icon {
        border-right: none;
      }

      .right_icon {
        border-left: none;
        cursor: pointer;
      }

      span {
        display: flex;
        margin-top: 3px;
        font-size: 0.875em;
        line-height: normal;
        color: $color-error;
      }

      .terms {
        flex-direction: column;
        width: 100%;
        max-width: 675px;
        margin-top: 10px;
        margin-bottom: 10px;

        .text {
          p {
            font-size: 0.9em;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 500;

            a {
              color: $color-dark-blue;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }

      .mt-30 {
        margin-top: 30px;
      }

      .mt-10 {
        margin-top: 10px;
      }

      .fw-bold {
        font-weight: 700;
      }

      .name {
        font-size: 20px;
      }
    }
  }
}