.footer {
  position: absolute;
  bottom: 0;
  background-color: $color-dark-blue;
  width: 100%;

  &__wrapper {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_company {
      color: $color-white;
      line-height: 18px;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 1rem;
    }

    &_links {
      color: $color-white;

      a {
        font-family: "DM Sans";
        font-weight: 600;
        font-size: 1rem;
        border-bottom: 1px solid;
      }
    }
    @media screen and (max-width: 756px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      > * {
        margin: 0.2rem;
      }
    }
  }
}
