.navbar {
  padding: 0.7rem 2rem;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
  z-index: 1;
  width: 100%;

  &__logo {
    cursor: pointer;
    img {
      width: auto;
      height: 58px;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      @media (max-width: 756px) {
        height: 38px;
      }
    }

    p {
      font-size: 1.05em;
      font-weight: 500;
    }
  }

  // .help_link {
  //   margin-right: 24px;

  //   @include lgScreen {
  //     margin: 0;
  //     margin-bottom: 18px;
  //     width: 100%;
  //   }
  // }

  // @include lgScreen {
  //   .lg_screen_display {
  //     .links {
  //       display: none;
  //     }

  //     .btn {
  //       display: none;
  //     }
  //   }
  // }
}

// @include mdScreen() {
//   .navbar_component {
//     display: none;
//   }
// }

// @include lgScreen() {
//   .navbar_component {
//     display: none;
//   }
// }
