.error_page {
  @include flex-position(flex-start, flex-start);
  gap: 10px;
  width: 100%;
  min-height: 100vh;
  background-color: $color-white;

  @media screen and (max-width: 767px) {
    padding: 60px 20px;
  }

  @media screen and (max-height: 670px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 450px) {
    padding-left: 18px;
    padding-right: 18px;
  }

  .page_frame {
    @include flex-position(center, center);
    width: 100%;
    height: 100vh;

    @media (max-width: 767px) or (max-height: 670px) {
      width: 100%;
      height: fit-content;
    }

    .page_content {
      width: 100%;
      max-width: 424px;

      .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 90px;
      }

      .loading-spinner {
        border: 4px solid $color-loader;
        border-top: 4px solid transparent;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      @media (max-width: 768px) {
        .loading-container {
          margin-top: 150px;
        }

        .loading-spinner {
          width: 80px;
          height: 80px;
        }
      }

      .header {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 2.3em;
      }

      .title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 38px;

        &.center {
          text-align: center;
        }
      }

      .sub_title {
        font-weight: 400;
        font-size: 18px;
        color: $color-black;

        &.center {
          text-align: center;
        }

        &.middle {
          margin-top: 40px;
          align-items: center;
          text-align: center;
          flex-direction: column;
          word-wrap: break-word;

          @include mdScreen {
            margin-top: 40px;
          }
        }

        &.bold {
          font-weight: 700;
        }
      }

      .warning_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          width: 70px;
          height: 70px;
        }
      }

      .image_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 20px;

        .profile_picture {
          width: 132px;
          height: 132px;
          border-radius: 50%;
          border: 1px solid $color-disabled;
        }

        img {
          width: 70px;
          height: 70px;
        }

        @include mdScreen {
          margin-top: 30px;
        }
      }

      .actions {
        // @include flex-position(flex-start, center);
        gap: 24px;
        // margin-top: 20px;

        .btn_primary {
          padding-top: 18px;
          padding-bottom: 18px;
          width: 50%;
          position: relative;
        }

        a {
          font-weight: 500;
        }

        @include mdScreen {
          // margin-top: 45px;
        }
      }

      .btn_secondary {
        padding-top: 15px;
        padding-bottom: 15px;
        color: $color-text-light;
        font-weight: 400;
        width: 100%;
        border: 0.5px solid #e7e9ee;

        .left_icon {
          margin-top: 3px;

          path {
            stroke: none;
            stroke-width: none;
          }
        }
      }

      .background_blur {
        width: 90%;
        max-width: 675px;
        height: 430px;
        left: 277;
        top: 346;
        position: absolute;
        opacity: 0.20;

        div.ellipse_left {
          width: 100%;
          max-width: 675px;
          height: 203.92px;
          left: 635.94;
          top: 28.81;
          position: absolute;
          opacity: 0.70;
          background: #FFE77B;
          box-shadow: 90px 90px 90px;
          border-radius: 9999;
          filter: blur(90px);
        }

        div.ellipse_right {
          width: 100%;
          max-width: 675px;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          opacity: 0.50;
          background: #00CBC2;
          box-shadow: 250px 250px 250px;
          border-radius: 9999;
          filter: blur(250px)
        }
      }

      .input_container {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid $color-input-border;
        border-radius: 5px;

        &:focus-within {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          outline: 0px auto -webkit-focus-ring-color !important;
          outline: none;
          border: 1px solid $color-primary !important;
          border-radius: 3px;
        }
      }

      input {
        flex: 1;
        border: none;
        padding: 8px;
        font-size: 1em;
        outline: none;
        background-color: transparent;

        &:focus {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          outline: 0px auto -webkit-focus-ring-color !important;
          outline: none !important;
          border: none !important;
          background-color: none;
        }
      }

      input::placeholder {
        color: #4F4F4F !important;
      }

      .input_icon {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;
      }

      .left_icon {
        border-right: none;
      }

      .right_icon {
        border-left: none;
        cursor: pointer;
      }

      span {
        display: flex;
        margin-top: 3px;
        font-size: 0.875em;
        line-height: normal;
        color: $color-error;
      }

      button {
        background-color: #002D2B;
        color: #fff;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        cursor: pointer;
      }

      .terms {
        flex-direction: column;
        width: 100%;
        max-width: 675px;
        margin-top: 10px;
        margin-bottom: 10px;

        .text {
          p {
            font-size: 0.9em;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 400;

            a {
              color: #01645F;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }

      .login_link {
        position: relative;
        text-align: center;
        margin-top: 5px;
        font-weight: 500;

        a {
          color: #002D2B;
          font-weight: 700;
          font-size: 16px;
          line-height: 19.96px;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .todo {
        text-align: left;
        padding: 10px;

        li {
          list-style-type: disc;
          text-align: left;
        }

        @include mdScreen {
          padding: 30px;
        }
      }
    }
  }
}