.complete {
  padding: 1rem;
  > * {
    margin: 1rem;
    @media (min-width: 1024px) {
      margin: 0.3rem 7rem;
    }
  }

  h1 {
    text-align: left;
    max-width: 1128px;
  }

  &__image {
    display: flex;
    justify-content: space-between;
    h3 {
      max-width: 556px;
    }
    &_left {
      display: flex;
      flex-direction: column;

      &-links {
        display: flex;
        flex-direction: column;
        p {
          font-size: 1.5rem;
          font-size: 700;
          color: #4685ec;
          margin-top: 10px;
        }
        .store-img {
          border: 1px solid #f4f4f4;
          border-radius: 4px;
          margin: 10px 0;
        }

        img {
          width: 160px;
        }
      }
    }

    &_right {
      display: flex;
      justify-content: right;

      img {
        width: 255px;
        @media (max-width: 756px) {
          width: 140px;
        }
        @media (max-width: 512px) {
          display: none;
        }
      }
    }
  }
}
