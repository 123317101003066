.email_link_page {
  @include flex-position(flex-start, flex-start);
  gap: 10px;
  width: 100%;
  min-height: 100vh;
  background-color: $color-white;

  @media screen and (max-width: 767px) {
    padding: 60px 20px;
  }

  @media screen and (max-height: 670px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 450px) {
    padding-left: 18px;
    padding-right: 18px;
  }

  .timeline {
    width: 50%;
    flex-direction: column;

    @include mdScreen {
      display: none;
    }

    @include smScreen() {
      display: none;
    }

    @include lgScreen() {
      display: none;
    }

    .left {
      left: 45px;
      position: absolute;
      justify-content: flex-start;
      align-items: center;
      display: inline-flex;
      gap: 0px;
    }

    .left:nth-child(1) {
      top: 100px;
    }

    .left:nth-child(1)::after {
      content: "";
      width: 50px;
      height: 0px;
      transform: rotate(-90deg);
      transform-origin: 0 0;
      border: 1px #7B8897 dashed;
      left: 19px;
      top: 88px;
      position: absolute
    }

    .left:nth-child(2) {
      top: 180px;
    }

    .ball_round {
      border-radius: 9999px;
      width: 30px;
      height: 30px;
      background: $color-white;
      margin: 5px;

      .number {
        text-align: center;
        font-size: 16px;
        font-family: 'DM Sans';
        font-weight: 400;
        margin: 5px;

        &.active {
          color: #00CBC2;
        }

        &.inactive {
          color: #ABB3BF;
        }
      }

      &.active {
        border: 1px #00CBC2 solid;
      }

      &.inactive {
        border: 1px #ABB3BF solid;
      }
    }

    .text {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      display: inline-flex;

      .text_main {
        text-align: center;
        font-size: 17px;
        font-family: 'DM Sans';
        font-weight: 500;
        word-wrap: break-word;

        &.active {
          color: #333333;
          font-style: bold;
        }

        &.inactive {
          color: #ABB3BF;
        }
      }

      .text_subtitle {
        text-align: center;
        color: #ABB3BF;
        font-size: 12px;
        font-family: 'DM Sans';
        font-weight: 400;
        line-height: 15.60px;
        word-wrap: break-word;

        &.active {
          color: #888888;
        }

        &.inactive {
          color: #ABB3BF
        }
      }
    }
  }

  .page_frame {
    @include flex-position(center, center);
    width: 100%;
    height: 100vh;

    @media (min-width: 991px) {
      @include flex-position(flex-start, center);
      width: 85%;
    }

    @media (min-width: 1024px) {
      width: 75%;
    }

    @media (max-width: 767px) or (max-height: 670px) {
      width: 100%;
      height: fit-content;
    }

    .page_content {
      width: 100%;
      max-width: 424px;

      .title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 48px;

        &.center {
          text-align: center;
        }
      }

      .sub_title {
        font-weight: 400;
        font-size: 16px;
        color: $color-black;

        &.center {
          text-align: center;
        }

        &.middle {
          margin-top: 40px;
          align-items: center;
          text-align: center;
          flex-direction: column;
          word-wrap: break-word;

          @include mdScreen {
            margin-top: 40px;
          }
        }
      }

      .image_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 40px;

        .profile_picture {
          width: 132px;
          height: 132px;
          border-radius: 50%;
          border: 1px solid $color-disabled;
        }

        img {
          width: 100px;
          height: 100px;
        }

        @include mdScreen {
          margin-top: 80px;
        }
      }

      .anchor_link {
        color: $color-dark-green;
        font-weight: 900;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;

        a {
          text-decoration: underline;
          font-weight: 700;
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
  }
}