/* Default styles for all screen sizes */
.mobile-bottom-nav {
  display: none;
}

@media (max-width: 768px) {
  .mobile-bottom-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    border-radius: 50px 50px 0px 0px;
  }

  .nav-item {
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: #888888;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    svg path {
      fill: #888888;
    }

    &.active {
      svg path {
        fill: #BE9137;
      }
  
      span {
        color: #BE9137;
      }
    }
  }

  .nav-item img {
    max-width: 24px;
    max-height: 24px;
  }
}