.search_input_container {
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }

  .input_container {
    display: flex;
    align-items: center;
    border: 1px solid $color-input-border;
    border-radius: 5px;
    padding: 8px;

    &:focus-within {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      outline: 0px auto -webkit-focus-ring-color !important;
      outline: none;
      border: 1px solid $color-primary !important;
      border-radius: 3px;
    }
  }

  .input_icon {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .left_icon {
    border-right: none;
    /* Remove the right border for the left icon */
  }

  .right_icon {
    border-left: none;
    cursor: pointer;
    /* Remove the left border for the right icon */
  }

  .text_input {
    flex: 1;
    border: none;
    padding: 8px;
    font-size: 1em;
    outline: none;

    &:focus {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      outline: 0px auto -webkit-focus-ring-color !important;
      outline: none !important;
      border: none !important;
    }
  }

  .text_input::placeholder {
    color: #888;
  }

  span {
    display: flex;
    margin-top: 3px;
    font-size: 0.875em;
    line-height: normal;
    color: $color-error;
  }
}