.logo_container {
  @include flex-position(center, center);

  img {
    width: 150px;
    height: auto;

    &.icon {
      border: 1px solid $color-white;
      border-radius: 7px;
    }
  }
}
