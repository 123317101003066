.my_dms_page_container {
  margin-top: -40px;
  width: 100%;
  min-height: 100vh;
  background: #ffffff;

  .inner_container {
    @include flex-position(center, inherit);
    flex-direction: column;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 80px;

    .header {
      @include flex-position(space-between, center);
      margin-bottom: 40px;

      @include mdScreen {
        flex-direction: column;
      }

      .title {
        color: $color-text;
        font-size: 1.1em;
        font-weight: 700;
      }

      .sub_title {
        margin-top: 3px;
        color: $color-text-light;
        font-size: 1em;
      }

      .btn {
        padding: 12px 24px;

        @include mdScreen {
          margin: 12px 0 0 auto !important;
          width: fit-content;
        }
      }
    }

    .todo_list {
      margin: 0 auto;
      margin-bottom: 50px;
      padding: 32px 24px;
      width: 100%;
      background: url("../../assets/img/todo-list-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .head_text {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 1.21em;
      }

      .list {
        @include flex-position(flex-start, center);
        flex-direction: column;
        gap: 20px;

        .item {
          padding: 12px 12px;
          text-align: center;
          color: $color-dark-green;
          width: 100%;
          background: $color-white;
          border: 1px solid $color-dark-green;
          border-radius: 50px;
          cursor: pointer;

          &.non_clickable {
            border: 1px dashed $color-dark-green;
            cursor: auto;
          }

          h6 {
            margin-bottom: 3px;
            font-size: 1em;
            font-weight: 600;
            letter-spacing: 0.2px;
          }

          p {
            font-size: 0.95em;
            font-weight: 400;
            letter-spacing: 0.2px;
          }

          img {
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
        }
      }
    }

    .creator_info {
      .creator_info_inner {
        margin: 0 auto;
        margin-top: 60px;
        max-width: 750px;
      }

      .back {
        display: block;
        color: #222222;
        cursor: pointer;
      }
    }

    .image_and_info {
      @include flex-position(center, center);
      gap: 24px;

      @include smScreen {
        flex-direction: column;
      }

      .image_container {
        position: relative;

        .profile_picture {
          @include circle(132px, 132px);
          border: 1px solid $color-input-border;
        }
      }

      .info {
        @include smScreen {
          text-align: center;
        }

        h6 {
          font-size: 1.125em;
          font-weight: 700;
        }

        p {
          margin-top: 8px;
          margin-bottom: 15px;
          text-transform: capitalize;

          span {
            font-style: normal;
            font-weight: 400;
            color: $color-text-light;
          }
        }

        .btn {
          padding: 7px 16px;
          border-radius: 4px;

          @include smScreen {
            margin: 0 auto;
            width: 370px;
          }
        }

        .socials {
          @include flex-position(flex-start, center);
          flex-wrap: wrap;
          gap: 18px;
          margin-top: 16px;
          margin-bottom: 10px;

          @include smScreen {
            justify-content: center;
          }

          a {
            @include flex-position(center, center);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            cursor: pointer;

            svg {
              width: 14px;
              height: 14px;
            }
          }

          img {
            @include circle(24px, 24px);
            cursor: pointer;
          }
        }
      }
    }

    .creators_listing {
      @include grid-columns(repeat(3, 1fr), 24px);

      @media (max-width: 1395px) {
        @include grid-columns(repeat(2, 1fr), 24px);
      }

      @media (max-width: 986px) {
        @include grid-columns(repeat(1, 1fr), 24px);
      }

      .image_and_info {
        align-items: flex-start !important;
        justify-content: flex-start !important;
        padding: 24px;
        border-radius: 12px;
        background: $color-white;
        box-shadow: 0px 7px 40px 0px rgba(0, 0, 0, 0.07);
        cursor: pointer;

        @media (max-width: 1070px) {
          padding: 24px 12px;
        }

        .profile_picture {
          width: 80px !important;
          height: 80px !important;
        }

        .info {
          @include smScreen {
            text-align: left !important;
          }

          h6 {
            margin-bottom: 5px;
            font-size: 1em;
            font-weight: 600;
          }

          p {
            margin: 0;
            margin-bottom: 4px;
            font-size: 0.9em;
            font-weight: 500;
            letter-spacing: 0.05px;
            text-transform: none;

            span {
              font-style: normal;
              font-weight: 400;
              color: $color-text-light;
            }
          }

          .status {
            margin-top: 10px;
            padding: 4px 8px;
            width: fit-content;
            font-size: 0.85em;
            font-weight: 500;
            color: $color-dark-green;
            border-radius: 4px;
            background: #dffbf9;
          }

          .socials {
            @include flex-position(flex-start, center);
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 12px;

            @include smScreen {
              justify-content: center;
            }

            img {
              @include circle(24px, 24px);
              cursor: pointer;
            }
          }
        }
      }
    }

    .links {
      @include flex-position(center, center);
      flex-direction: column;
      gap: 24px;
      margin-top: 40px;
      width: 100%;
      max-width: 675px;

      a {
        padding: 15px 12px;
        width: 100%;
        text-align: center;
        background: $color-white;
        border-radius: 50px;
        filter: drop-shadow(0px 7px 30px rgba(0, 0, 0, 0.07));

        @include mdScreen {
          @include ellipsis;
          max-width: 100%;
        }
      }
    }

    .widget-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
    }

    .widget {
      width: 500px;
      border-left: 3px solid #00CBC2;
      border-radius: 20px;
      margin-right: 20px;
      background: #ffffff;
      box-shadow: 0px 7px 30px 0px #00000012;
      overflow: hidden;
      margin-bottom: 20px;

      .widget-content {
        display: flex;
        flex-direction: column;

        .small-view,
        .large-view {
          padding: 10px;
          max-height: 100%; // Limit the height of the maximized widget
          overflow-y: auto;
        }

        .small-view {
          height: 10%;

          .widget-header {
            display: flex;
            align-items: center;
            // padding: 10px;

            .widget-icon {
              margin-right: 10px;
              margin-top: 10px;
              height: 35px;
              width: 35px;
            }

            h2 {
              font-size: 18px;
              color: #CD3602;
              line-height: 0px;
              font-style: italic;
              font-family: "DM Sans";
            }
          }

          span {
            display: flex;
            padding-left: 45px;
            margin-top: 0px;
            word-wrap: break-word;
            line-height: 18.2px;
            color: #4F4F4F;
            font-weight: 400;
            font-family: "DM Sans";
            width: auto;
            font-size: 16px;
          }

          p {
            text-align: center;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          &.hide {
            display: none;
          }

          &.show {
            display: block;
          }

          &.can-open {
            cursor: pointer;
          }
        }

        .large-view {
          &.show {
            display: block;
          }

          &.hide {
            display: none;
          }

          .widget-header {
            @include flex-position(center, center);
            display: flex;
            align-items: center;
            padding: 10px;

            .widget-icon {
              display: flex;
              justify-content: center;
              font-size: 24px;
              margin-right: 10px;
            }

            h2 {
              font-size: 18px;
              color: #CD3602;
              font-style: italic;
              font-family: "DM Sans";
            }
          }

          .widget-icon-large {
            @include flex-position(center, center);
            font-size: 48px;
            text-align: center;
            align-items: center;
          }

          h3 {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            font-family: "DM Sans";
            color: #4F4F4F;
          }

          button {
            background-color: #002D2B;
            color: #fff;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-left: 10px;
            cursor: pointer;
          }

          .widget-footer {
            background-color: #F9FFFF;
            padding: 10px;
            width: 100%;
            text-align: center;
            margin-top: 0;
            color: #ffffff;
            border-radius: 0px 0px 5px 5px;

            p {
              font-size: 14px;
              font-weight: 700;
              font-family: "DM Sans";
            }
          }

          .close-icon {
            display: flex;
            cursor: pointer;
            justify-content: right;
          }

          .terms {
            flex-direction: column;
            width: 100%;
            max-width: 675px;
            margin-top: 10px;
            margin-bottom: 10px;

            .text {
              p {
                font-size: 0.9em;
                text-align: center;
                font-family: 'DM Sans';
                font-weight: 400;

                a {
                  color: #01645F;
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .input_container {
        display: flex;
        align-items: center;
        border: 1px solid $color-input-border;
        border-radius: 5px;

        &:focus-within {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          outline: 0px auto -webkit-focus-ring-color !important;
          outline: none;
          border: 1px solid $color-primary !important;
          border-radius: 3px;
        }
      }

      input {
        flex: 1;
        border: none;
        padding: 8px;
        font-size: 1em;
        outline: none;

        &:focus {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          outline: 0px auto -webkit-focus-ring-color !important;
          outline: none !important;
          border: none !important;
          background-color: none;
        }
      }

      .input_icon {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;
      }

      .left_icon {
        border-right: none;
      }

      .right_icon {
        border-left: none;
        cursor: pointer;
      }

      span {
        display: flex;
        margin-top: 3px;
        font-size: 0.875em;
        line-height: normal;
        color: $color-error;
      }
    }

    @media (max-width: 768px) {
      .widget-container {
        flex-direction: column;
      }

      .widget {
        width: 100%;
      }
    }

    .following {
      display: flex;
      flex-direction: column;
      padding: 20px;

      h2 {
        font-family: "DM Sans";
        font-weight: 700;
        font-size: 18px;
        color: #222222;
        margin-bottom: 5px;
      }

      .sub_text {
        font-size: 15px;
        margin-bottom: 10px;
      }

      .creators-list {
        @include grid-columns(repeat(1, 1fr), 24px);

        @media (max-width: 1395px) {
          @include grid-columns(repeat(1, 1fr), 24px);
        }

        @media (max-width: 986px) {
          @include grid-columns(repeat(1, 1fr), 24px);
        }

        .follower {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: 1px solid #E7E9EE;
        }

        .profile-picture {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
          cursor: pointer;
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          cursor: pointer;
        }

        .name {
          font-size: 16px;
          color: #222222;
          font-weight: 500;
          font-family: "DM Sans";
          margin: 0;
        }

        .category {
          font-size: 14px;
          color: #4F4F4F;
          font-weight: 400;
          margin: 0;
        }

        .follow-button {
          border: 1px;
          border-radius: 4px;
          padding: 5px 15px;
          cursor: pointer;
          font-family: "DM Sans";
          font-size: 14px;
          font-weight: 400;

          &.following {
            background-color: #002D2B;
            color: #FFFFFF;
          }

          &.not-following {
            background-color: #ffffff;
            color: #002D2B;
          }
        }
      }
    }

    .might-like {
      padding: 20px;
      // margin-top: 20px;

      .might-like-header {
        display: flex;
        justify-content: space-between;
        font-family: "DM Sans";
        font-weight: 700;
        // margin-bottom: 18px;
      }

      .title {
        color: #222222;
        font-size: 18px;
      }
      
      .sub_text {
        font-size: 14px;
        font-weight: 400;
      }

      .see_more {
        color: #BE9137;
        font-size: 15px;
        flex: 2;
        text-align: right;
      }

      .list-creators {
        margin-top: 10px;
        @include grid-columns(repeat(1, 1fr), 24px);

        @media (max-width: 1395px) {
          @include grid-columns(repeat(1, 1fr), 24px);
        }

        @media (max-width: 986px) {
          @include grid-columns(repeat(1, 1fr), 24px);
        }

        .follower {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: 1px solid #E7E9EE;
        }

        .profile-picture {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
          cursor: pointer;
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          cursor: pointer;
        }

        .name {
          font-size: 16px;
          color: #222222;
          font-weight: 500;
          font-family: "DM Sans";
          margin: 0;
        }

        .category {
          font-size: 14px;
          color: #4F4F4F;
          font-weight: 400;
          margin: 0;
        }

        .follow-button {
          border: 1px #002D2B solid;
          border-radius: 4px;
          padding: 5px 15px;
          cursor: pointer;
          font-family: "DM Sans";
          font-size: 14px;
          font-weight: 400;

          &.following {
            background-color: #002D2B;
            color: #FFFFFF;
          }

          &.not-following {
            background-color: #ffffff;
            color: #002D2B;
          }
        }
      }
    }

    .follower-tagline {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
      margin-top: 25px;
      padding: 20px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        margin-bottom: 10px;
        flex-direction: row;

        h2 {
          display: flex;
          text-align: left;
          align-items: center;
          font-family: "DM Sans";
          font-weight: 700;
          font-size: 18px;
          color: #222222;

          svg {
            path {
              fill: black;
            }
          }

          @include mdScreen() {
            flex: 3;
            text-align: center;
            justify-content: center;
          }
        }

        .back-button {
          margin-right: auto;
          display: none;

          @media (max-width: 768px) {
            display: flex;
          }
        }
      }

      .sub_text {
        font-size: 15px;
        text-align: left;
        font-weight: 400;
        font-family: "DM Sans";
        color: #222222;
        margin-bottom: 10px;
      }
    }

    .page-container {
      display: flex;
      align-items: stretch;
      padding: 0px;

      .left-section {
        flex: 0 0 600px;
        border-right: 1px solid #EFEFEF;

        @include mdScreen() {
          border-right: 0;
        }
      }

      .right-section {
        padding: 20px;
        flex: 1;
      }

      .border {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        background-color: #333;
      }
    }

    .creators_page {
      display: flex;
      flex-direction: column;
      width: 600px;

      @include mdScreen() {
        width: auto;
      }
    }

    /** For the notification segment **/
    .notifications {
      margin-top: 20px;
      padding: 20px;
      position: relative;

      @include mdScreen () {
        margin-top: 0px;
      }
    }

    @media (max-width: 768px) {
      .page-container {
        flex-direction: column;
      }

      .left-section {
        order: 1
      }

      .right-section {
        order: 2;
      }

      .border {
        display: none;
      }
    }

    .has_extension_prompt {
      padding: 20px;

      &.hide {
        display: none;
      }

      .prompt_content {
        display: flex;
        border-left: 3px solid #11B8A4;
        background-color: #EDFBF1;
        padding: 20px;
        flex-direction: row;

        .checkmark {
          justify-content: flex-start;
          margin-right: 20px;
        }

        .content {
          font-weight: 400;
          width: 100%;
          color: #333333;
          word-wrap: break-word;
          justify-content: center;
        }

        .close {
          justify-content: flex-end;
          cursor: pointer;
        }
      }
    }

    .how-it-works {
      display: flex;
      flex-direction: column;
      justify-content: center;

      header {
        padding: 20px;
        text-align: center;
      }

      section {
        width: 100%;
        margin: 20px auto;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        justify-content: center;
      }

      h1 {
        font-size: 2em;
        margin-bottom: 10px;
      }

      h2 {
        font-size: 1.5em;
        margin-bottom: 15px;
      }

      ol {
        list-style: none;
        // counter-reset: item;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        // counter-increment: item;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
      }

      li::before {
        // content: counter(item);
        font-weight: bold;
        font-size: 1.2em;
        color: #4CAF50;
        margin-bottom: 5px;
      }

      img {
        max-width: 100%;
        width: fit-content;
        margin-top: 10px;
      }

      ul {
        margin: 0px;
        padding: 0px 20px;

        li {
          margin: 0px;
          padding-left: 20px;
        }
      }
    }

    .guide_bar {
      padding: 0px 0px 0px 20px;
      display: flex;
      justify-content: space-between;
      line-height: 26px;
      align-items: stretch;
      width: auto;

      .guide_text {
        display: flex;
        align-items: center;
        line-height: 38.65px;
        font-size: 20px;
        font-weight: 700;
        margin-right: 10px;

        @include smScreen () {
          display: none;
        }

        svg {
          path {
            stroke: #BE9137;
          }
        }
      }

      .guide_button {
        margin-right: 20px;
        text-align: right;

        @include smScreen() {}
      }
    }

    .zero_state {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;

      .logo {
        align-items: center;
        justify-content: center;
      }

      .title {
        margin-top: 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #4F4F4F;
      }

      .sub_title {
        margin-top: 10px;
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #4F4F4F
      }

      .action {
        margin-top: 30px;

        button {
          width: 374px;
        }
      }
    }
  }
}