.modal_container {
  position: fixed;
  z-index: 1;
  padding: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  @include mdScreen {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include smScreen {
    padding-left: 12px;
    padding-right: 12px;
  }

  .modal_content {
    background-color: $color-white;
    margin: auto;
    border: 1px solid $color-disabled;
    width: 100%;
    max-width: 767px;
    border-radius: 12px;

    &.size_sm {
      width: 378px;
      max-width: 378px;

      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    &.size_md {
      width: 480px;
      max-width: 480px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    &.fullscreen {
      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: none;
      }
    }

    @include mdScreen {
      width: 100%;

      &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateX(0);
      }
    }

    .section_header_container {
      padding: 12px 24px;

      @include mdScreen {
        padding: 12px;
      }
    }

    .action {
      @include flex-position(flex-end, center);
      margin-top: 40px;

      @include smScreen {
        margin-top: 30px;
      }
    }
  }

  .dummy_modal {
    padding: 70px 30px;

    @include smScreen {
      padding: 48px 12px;
    }

    .body {
      text-align: center;
      margin-bottom: 0px;

      img {
        margin-bottom: 40px;
        width: 204px;
        height: auto;

        @include smScreen {
          margin-bottom: 30px;
          width: 150px;
        }
      }

      p {
        font-size: 1.4em;
        font-weight: 700;
        color: $color-text;

        @include smScreen {
          font-size: 1.15em;
        }
      }

      button {
        margin-top: 15px;
        width: 100%;
        padding: 12px;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 16px;
        color: #4F4F4F;
        font-weight: 400;
      }
    }

    .action {
      @include flex-position(center, center);

      .btn {
        padding: 16px 12px;
        width: 100%;

        @include smScreen {
          padding: 14px 12px;
        }
      }
    }
  }

  .interest_modal {
    .body {
      padding: 0 24px;

      p {
        font-size: 16px;
        font-weight: 500;
        font-family: "DM Sans";
        color: #4F4F4F;
        margin-top: 0px;
        margin-bottom: 20px;
      }

      @include mdScreen {
        padding: 0 12px;
      }

      .pill-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .pill {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 5px 10px;
        border-radius: 30px;
        cursor: pointer;
        background-color: transparent;
        color: #4F4F4F;
        transition: background-color 0.3s, color 0.3s;
        border: 1px solid #E7E9EE;
        font-family: "DM Sans";
        font-weight: 400;

        &:hover {
          background-color: #E4F9F6;
          color: #002D2B;
        }

        &.selected {
          background-color: #002D2B;
          color: #ffffff;
        }
      }

      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;

        img {
          width: 200px;
          height: 200px;
        }
      }
    }

    .action {
      @include flex-position(center, center);
      padding: 0 24px 20px 24px;

      @include mdScreen {
        padding: 0 12px 20px 12px;
      }

      .cancel {
        margin-right: 24px;
        font-weight: 500;
      }

      .btn {
        padding: 16px 12px;
        width: 100%;

        @include smScreen {
          padding: 14px 12px;
        }
      }
    }

    button:disabled {
      background-color: $color-disabled;
      position: relative;
      color: #ABB3BF;
      border: #ABB3BF;
    }

    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #00CBC2;
      fill: none;
      animation: stroke 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards
    }

    .checkmark {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: block;
      stroke-width: 1;
      stroke: #fff;
      font-weight: 800;
      stroke-miterlimit: 10;
      margin: 10% auto;
      box-shadow: inset 0px 0px 0px #00CBC2;
      animation: fill .5s ease-in-out .5s forwards, scale .3s ease-in-out .9s both
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0
      }
    }

    @keyframes scale {

      0%,
      100% {
        transform: none
      }

      50% {
        transform: scale3d(1.1, 1.1, 1)
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 80px #00CBC2;
      }
    }
  }

  .prompt_modal {
    .body {
      padding: 0 24px;

      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 18px;
        color: #4F4F4F;
        font-weight: 400;
        text-align: center;
      }

      button {
        margin-top: 20px;
        width: 100%;
        padding: 12px;
        margin-bottom: 10px;
      }
    }
  }

  .guide_modal {
    padding: 20px 0px 0px 0px;

    .body {
      padding: 0px 20px 20px;
    }

    .content {
      padding: 10px 30px;

      .item {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          margin-bottom: 10px;

          .main {
            display: flex;
            font-size: 22px;
            font-weight: 700;
          }
          
          .indicator {
            font-size: 15.5px;
            font-weight: 600;
            color: #002D2B;
          }
        }

        .subtitle {
          font-size: 16px;
          margin-bottom: 15px;
        }

        img {
          width: 100%;
          height: 40%;
        }

        &.active {
          display: block;
        }
      }

      .slick-prev::before {
        content: '<';
        font-family: "DM Sans";
        color: #FFFFFF;
        font-weight: 350;
        width: 30px;
        height: 30px;
        opacity: 1;
        text-align: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 65%;
        left: 50%;
      }

      .slick-next::before {
        content: '>';
        font-family: "DM Sans";
        color: #FFFFFF;
        font-weight: 350;
        width: 30px;
        height: 30px;
        opacity: 1;
        text-align: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 65%;
        left: 50%;
      }
    }

    .g-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;

        img {
          max-width: 100%;
          height: auto;
          margin-right: 10px;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .close {
        cursor: pointer;
      }
    }

    .footer {
      position: relative;
      padding: 18px 25px;
      background-color: $color-dark-green;
      border-radius: 0px 0px 5px 5px;

      .lg_screen_display {
        @include flex-position(space-between, center);

        .company_text {
          @include flex-position(flex-start, center);
          color: $color-white;
          line-height: 18px;
          font-family: 'DM Sans';
          font-weight: 400;
          font-size: 14px;
        }

        .site_links {
          @include flex-position(center, center);
          list-style: none;
          margin-right: 24px;
          gap: 8px;
          color: $color-white;
          line-height: 18px;

          a {
            background: none;
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 14px;
          }

          @include smScreen() {
            display: none;
          }
        }
      }
    }
  }
}