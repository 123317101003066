.button_component {
  &.loading {
    padding: 8px 30px !important;
    padding-bottom: 9px !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #E7E9EE;
    color: #ABB3BF;
    border: 0;
  }

  font-size: 1em;

  .left_icon {
    margin-right: 8px;
  }

  .right_icon {
    margin-left: 8px;
  }

  svg {
    width: 22px;
    height: 22px;
  }
}
