.topbar_container {
  @include flex-position(space-between, center);
  margin-bottom: 40px;
  padding: 18px 30px;
  width: 100%;
  background: $color-white;
  // box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);

  @media screen and (max-width: 850px) {
    padding: 14px 12px;
  }

  .d_flex {
    @include flex-position(flex-start, center);

    &.end {
      @include flex-position(flex-end, center);
    }
  }

  .sidebar_toggler {
    @include flex-position(flex-start, center);
    margin-right: 12px;
  }

  .title {
    font-size: 1em;
    font-weight: 500;
    color: $color-text-light;
    @include flex-position(flex-start, center);

    @media (max-width: 768px) {
      display: none;
    }

    &.desktop {
      display: none;
    }

    &.mobile {
      display: none;

      @media (max-width: 768px) {
        display: flex;
        font-weight: 700;
        font-size: 18px;
        @include flex-position(center, center)
      }
    }
  }

  .notification {
    @include flex-position(flex-start, center);
    margin-right: 50px;
    cursor: pointer;
    background: #E7E7E7;
    border-radius: 9999px;
    display: none;
    height: 32px;
    width: 32px;

    @include smScreen {
      margin-right: 10px;
      display: block;
    }

    svg {
      padding: 6px 0 1px 7px;
      width: 24px;
      height: 24px;
    }

    &.has_notice::before {
      display: block;
      content: '';
      background-color: #CD3602;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 23px;
      right: 36px;
      transform: translate(50%, -50%);
    }
  }

  .user {
    @include flex-position(flex-start, center);

    .name {
      @include ellipsis;
      margin-right: 5px;
      padding: 9px 0;
      font-size: 1em;
      letter-spacing: 0.3px;
      color: $color-text;
      max-width: 150px;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }
}