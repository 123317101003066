.settings_container {
  width: 100%;

  .nav-tabs {
    width: fit-content;

    @include mdScreen {
      width: 531px;
    }
  }

  .tab_content {
    .profile_container {
      @include flex-position(flex-start, flex-start);

      @include smScreen {
        flex-direction: column;
      }

      .information {
        padding-right: 24px;
        width: 50%;
        border-right: 1px solid $color-disabled-1;

        @include smScreen {
          padding: 0;
          padding-bottom: 40px;
          margin-bottom: 40px;
          width: 100%;
          border: none;
          border-bottom: 1px solid $color-disabled-1;
        }

        .header {
          @include flex-position(space-between, flex-start);

          .image_and_info {
            @include flex-position(space-between, center);
            gap: 24px;

            @include lgScreen {
              align-items: flex-start;
              flex-direction: column;
            }

            .image_container {
              position: relative;

              .profile_picture {
                @include circle(132px, 132px);
                border: 1px solid $color-input-border;
              }

              .camera_icon {
                @include circle(30px, 30px);
                position: absolute;
                right: 5px;
                bottom: 5px;
                cursor: pointer;
              }

              .file_input {
                display: none;
              }
            }

            .info {
              h6 {
                font-size: 1.125em;
                font-weight: 700;
              }

              p {
                margin-top: 8px;

                span {
                  font-style: italic;
                  font-size: 0.91em;
                  letter-spacing: 0.2px;
                  color: $color-text-light;
                }
              }

              .socials {
                @include flex-position(flex-start, center);
                flex-wrap: wrap;
                gap: 12px;
                margin-top: 16px;

                img {
                  @include circle(24px, 24px);
                  cursor: pointer;
                }
              }
            }
          }

          .edit {
            width: fit-content;
            cursor: pointer;
          }
        }

        .content {
          margin-top: 40px;

          .grid_view {
            @include grid-columns(repeat(3, 1fr), 24px);
            margin-bottom: 24px;

            @include xlgScreen {
              @include grid-columns(repeat(2, 1fr), 24px);
            }

            @include lgScreen {
              @include grid-columns(repeat(1, 1fr), 24px);
            }
          }

          .item {
            h6 {
              margin-bottom: 4px;
              font-size: 0.95em;
              font-weight: 500;
            }

            p {
              font-size: 1em;
            }
          }
        }
      }

      .actions {
        padding-left: 24px;
        width: 50%;

        @include smScreen {
          padding: 0;
          width: 100%;
        }

        .section_title {
          margin-bottom: 32px;
          font-weight: 500;
        }

        .item {
          margin-top: 24px;
          padding: 14px 28px;
          font-size: 0.9em;
          max-width: 466px;
          border: 1px solid $color-disabled-1;
          cursor: pointer;
        }
      }
    }

    .change_info_container {
      .form_container {
        margin-bottom: 60px;
        padding-bottom: 60px;
        border-bottom: 1px solid $color-disabled-1;

        &:last-child {
          margin-bottom: 30px;
          padding: 0px;
          border: none;
        }

        .title {
          margin-bottom: 24px;
          font-size: 1.125em;
          font-weight: 700;
        }

        .form {
          max-width: 450px;

          .action {
            margin-top: 38px;

            .btn {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }

    .themes_container {
      .list_items {
        @include flex-position(flex-start, center);
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 46px;
        padding: 62px 40px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.07);

        @include smScreen() {
          padding: 32px 12px;
        }

        .item {
          padding: 66px 24px;
          width: 370px;
          height: fit-content;
          background-size: cover;
          background-position-x: center;
          background-repeat: no-repeat;
          border-radius: 12px;
          box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.07);
          cursor: pointer;

          &.selected {
            border: 3px solid $color-primary !important;
          }

          .creator_info {
            .image_and_info {
              @include flex-position(center, center);
              gap: 16px;

              .image_container {
                position: relative;

                .profile_picture {
                  @include circle(90px, 90px);
                  border: 1px solid $color-input-border;
                }
              }

              .info {
                h6 {
                  margin: 0;
                  margin-bottom: 4px;
                  font-size: 0.9em;
                  font-weight: 700;
                }

                p {
                  margin: 0;
                  margin-bottom: 10px;
                  font-size: 0.8em;
                  text-transform: capitalize;

                  &.handle {
                    margin-bottom: 6px;
                    text-transform: lowercase;
                  }
                }

                .btn {
                  padding: 6px 10px;
                  font-size: 0.75em;
                  filter: drop-shadow(0px 7px 30px rgba(0, 0, 0, 0.07));
                }

                .socials {
                  @include flex-position(flex-start, center);
                  flex-wrap: wrap;
                  gap: 10px;
                  margin-top: 12px;

                  div {
                    @include circle(20px, 20px);
                    @include flex-position(center, center);
                  }
                }
              }
            }

            .links {
              @include flex-position(center, center);
              flex-direction: column;
              gap: 14px;
              margin-top: 32px;
              width: 100%;

              p {
                @include ellipsis;
                padding: 9px 10px;
                font-size: 0.75em;
                font-weight: 500;
                width: 100%;
                max-width: 100%;
                text-align: center;
                background: $color-white;
                border-radius: 50px;
                border: 1px solid $color-white;
                filter: drop-shadow(0px 7px 30px rgba(0, 0, 0, 0.07));
              }
            }

            .footer {
              @include flex-position(center, center);
              margin-top: 48px;
              margin-bottom: -20px;
              padding: 14px 8px;
              width: 100%;
              border-radius: 12px;

              img {
                margin-right: 12px;
                width: 32px;
                height: 32px;
                cursor: pointer;
              }

              .info {
                p {
                  font-size: 0.75em;

                  span {
                    font-weight: 500;
                    color: $color-primary;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .notifications_container {
      .title {
        margin-bottom: 24px;
        font-size: 1.125em;
        font-weight: 700;
      }

      .list_items {
        max-width: 450px;

        .item {
          @include flex-position(space-between, center);
          padding: 20px 0px;
          border-top: 1px solid $color-disabled-1;

          &:first-child {
            border-top: none;
          }
        }
      }
    }
  }

  .account-section {
    padding: 20px;
    border-radius: 5px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: "Gelion";
    font-weight: 700;
    color: #333333;
  }

  .menu-list {
    list-style: none;
    padding: 0;
  }

  .menu-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;

    &.border {
      border-bottom: 1px solid #ccc;
    }
  }

  .menu-icon {
    font-size: 20px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DFFBF9;
    border-radius: 50%;
  }

  .menu-name {
    flex-grow: 1;
    font-size: 18px;
    margin-right: 10px;
    color: #4F4F4F;
    font-weight: 400;
    font-family: "DM Sans";
  }

  .arrow-indicator {
    font-size: 18px;
    color: #4F4F4F;
  }

  .user_profile {
    width: 100%;
    
    .header {
      display: none;
      justify-content: space-between;
      color: #fff;
      padding: 10px 20px;
      align-items: center;

      @media (max-width: 768px) {
        display: flex;
        text-align: center;
        align-items: center;
        margin-right: 130px;
      }
    }
    
    h1 {
      margin: 0;
      font-size: 24px;
      text-align: center;

      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }
    
    .back-button {
      margin-right: auto;
      display: flex;
      align-items: center;
      padding: 20px 0 0 0;

      @media (max-width: 768px) {
        margin-right: 0;
      }
    }

    main {
      padding: 20px;
    }

    .profile-form {
      align-items: center;
      text-align: left;
      max-width: auto;
    }

    .profile-notice {
      margin-bottom: 20px;

      span {
        font-family: "DM Sans";

        &.title {
          font-size: 18px;
          font-weight: 600;
          color: #CD3602;
        }

        &.subtitle {
          font-size: 16px;
          font-weight: 400;
          color: #4F4F4F;
        }
      }
    }

    .input-group {
      margin-bottom: 20px;
    }

    label {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
      font-style: italic;
      font-family: "DM Sans";
      color: #222222;
    }

    span {
      font-weight: 400;
      font-family: "DM Sans";
    }

    // input[type="text"],
    // input[type="email"] {
    //   width: 100%;
    //   padding: 10px;
    //   border: 1px solid #ccc;
    //   border-radius: 5px;
    // }

    .image-upload {
      display: flex;
      @include flex-position(flex-start, flex-start);
      position: relative;
      margin-top: 10px;
      margin-bottom: 50px;
    }

    .image-frame {
      width: 80px;
      height: 80px;
      background-color: #DFFBF9;
      border-radius: 50%;
      // margin: 0 auto;
      cursor: pointer;
      position: relative;
    }

    .image-frame .preview-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .image-icon {
      position: absolute;
      bottom: 4px;
      right: 4px;
      background-color: #E8E6ED;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .actions {
      @include flex-position(flex-start, center);
      gap: 24px;
      margin-top: 70px;

      .btn_primary {
        padding-top: 14px;
        padding-bottom: 14px;
        width: 100%;
        position: relative;
      }

      @include mdScreen {
        margin-top: 70px;
      }
    }
  }
}