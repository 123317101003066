.heading {
  font-weight: 700;
  font-size: 2.5rem;
  margin: 1.875rem 0 1rem 0;
}

.subHeading {
  font-weight: 500;
  font-size: 1.375rem;
  margin: 1rem 0 1rem 0;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 2.25rem;
  }
  .subHeading {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 992px) {
  .heading {
    font-size: 2rem;
  }
  .subHeading {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 1.75rem;
  }
  .subHeading {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 576px) {
  .heading {
    font-size: 1.5rem;
  }
  .subHeading {
    font-size: 1.1rem;
  }
}
