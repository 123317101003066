.sidebar_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: $color-dark-green;
  z-index: 1;
  display: none;

  &.open {
    display: block;

    @media screen and (max-width: 850px) {
      &::after {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 255px;
        content: "";
        background: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .sidebar_container_inner {
    padding: 0px;
    padding-bottom: 30px;
    height: 100vh;
    width: 255px;
    overflow-y: scroll;
    background: rgba(255, 80, 1, 0.05);

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      width: 0px;
    }

    .padding_left_right {
      padding-right: 25px;
      padding-left: 25px;
    }

    .sidebar_toggler {
      position: absolute;
      top: 12px;
      right: 12px;

      svg {
        width: 30px;
        height: 30px;

        path {
          fill: $color-white;
        }
      }
    }

    .logo_container {
      justify-content: center;
      padding: 32px 0px;

      @media screen and (max-width: 850px) {
        margin-top: 45px;
      }

      img {
        margin-right: 8px;
        width: 45px;
        height: auto;

        &.icon {
          border: none;
        }
      }

      p {
        font-weight: 500;
        font-size: 1em;
        color: $color-white;
      }
    }

    hr {
      margin: 0 auto;
      margin-bottom: 20px;
      width: 75%;
      height: 1px;
      background: $color-white;
    }

    .links_container {
      padding-left: 22px;

      .link {
        @include flex-position(flex-start, center);
        padding: 14px 12px 14px 18px;
        font-size: 1em;
        color: $color-white;
        width: 100%;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          background: rgba(70, 61, 107, 0.05);
        }

        svg {
          margin-top: -1px;
          margin-right: 10px;
          width: 24px;
          height: 24px;
        }

        &.active {
          color: $color-text;
          background: $color-white;
          border-radius: 12px 0px 0px 12px;

          svg path {
            fill: $color-text;
          }
        }
      }
    }

    .bottom_links {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;

      @media screen and (max-height: 620px) {
        position: relative;
        bottom: 0;
        margin-top: 24px;
      }

      hr {
        margin: 33px auto;
        width: 75%;
        height: 1px;
        background: $color-white;
      }
    }
  }
}