.signIn {
  width: 100%;
  font-family: "DM Sans";
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .timeline {
    width: 50%;
    flex-direction: column;

    @include mdScreen {
      display: none;
    }

    @include smScreen() {
      display: none;
    }

    @include lgScreen() {
      display: none;
    }

    .left {
      left: 45px;
      position: absolute;
      justify-content: flex-start;
      align-items: center;
      display: inline-flex;
      gap: 0px;
    }

    .left:nth-child(1) {
      top: 100px;
    }

    .left:nth-child(1)::after {
      content: "";
      width: 50px;
      height: 0px;
      transform: rotate(-90deg);
      transform-origin: 0 0;
      border: 1px #7b8897 dashed;
      left: 19px;
      top: 88px;
      position: absolute;
    }

    .left:nth-child(2) {
      top: 180px;
    }

    .ball_round {
      border-radius: 9999px;
      width: 30px;
      height: 30px;
      background: $color-white;
      margin: 5px;

      .number {
        text-align: center;
        font-size: 16px;
        font-family: "DM Sans";
        font-weight: 400;
        margin: 5px;

        &.active {
          color: #00cbc2;
        }

        &.inactive {
          color: #abb3bf;
        }
      }

      &.active {
        border: 1px #00cbc2 solid;
      }

      &.inactive {
        border: 1px #abb3bf solid;
      }
    }

    .text {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      display: inline-flex;

      .text_main {
        text-align: center;
        font-size: 17px;
        font-family: "DM Sans";
        font-weight: 500;
        word-wrap: break-word;

        &.active {
          color: #333333;
          font-style: bold;
        }

        &.inactive {
          color: #abb3bf;
        }
      }

      .text_subtitle {
        text-align: center;
        color: #abb3bf;
        font-size: 12px;
        font-family: "DM Sans";
        font-weight: 400;
        line-height: 15.6px;
        word-wrap: break-word;

        &.active {
          color: #888888;
        }

        &.inactive {
          color: #abb3bf;
        }
      }
    }
  }

  &__form {
    flex-direction: column;
    width: 100%;
    max-width: 424px;

    &_buttons {
      @include flex-position(flex-start, center);
      gap: 24px;
      margin-top: 20px;

      .btn_primary {
        width: 27.5rem;
        border-radius: 42px;
        font-weight: 700;
        font-size: 1.5rem;
      }

      a {
        font-weight: 500;
      }
    }

    .form-group {
      label {
        font-size: 1rem;
        font-style: italic;
        font-weight: 400;
      }
    }

    &_anchor {
      text-align: center;
      margin-top: 0.3rem;
      font-size: 1.2rem;

      button {
        text-decoration: underline;
        font-weight: 600;
        background: none;
        border: none;
      }

      .error {
        color: $color-error;
      }
    }

    .banner {
      padding: 12px;
      max-width: 675px;
      width: 100%;
      border-radius: 12px;
      border-left: 5px solid $color-primary;
      margin-top: 20px;
      box-shadow: $box-shadow;
      background: white;
      position: relative;

      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .info {
        margin-top: 5px;
        padding: 10px;

        p {
          font-size: 1em;
          font-weight: 700;
          margin-bottom: 10px;
          font-style: italic;
        }

        ul.info_item {
          list-style: none;
          padding-left: 12px;

          li::before {
            content: "\2022";
            display: inline-block;
            width: 0.7em;
            margin-left: -1em;
          }
        }
      }
    }

    .background_blur {
      width: 90%;
      max-width: 675px;
      height: 430px;
      left: 277;
      top: 346;
      position: absolute;
      opacity: 0.2;

      div.ellipse_left {
        width: 100%;
        max-width: 675px;
        height: 203.92px;
        left: 635.94;
        top: 28.81;
        position: absolute;
        opacity: 0.7;
        background: #ffe77b;
        box-shadow: 90px 90px 90px;
        border-radius: 9999;
        filter: blur(90px);
      }

      div.ellipse_right {
        width: 100%;
        max-width: 675px;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        opacity: 0.5;
        background: #00cbc2;
        box-shadow: 250px 250px 250px;
        border-radius: 9999;
        filter: blur(250px);
      }
    }

    .creator_access {
      margin-top: 50px;
      position: relative;
      text-align: center;
      font-size: 1.25rem;

      a {
        text-decoration: underline;
        font-weight: 600;
      }
    }

    button:disabled {
      background-color: $color-disabled;
      position: relative;
      color: #abb3bf;
      border: #abb3bf;
    }

    button {
      position: relative;
    }

    .divider {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    .line {
      flex-grow: 1;
      height: 1px;
      background-color: #ccc;
      border: none;
    }

    .or {
      margin: 0 10px;
      color: #4f4f4f;
      font-weight: 500;
    }

    .social-login {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      color: #000000;
      transition: background-color 0.3s ease;
      border: 1px solid #d5d9df;
      border-radius: 42px;
    }

    .social-login img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }

    .social-login span {
      text-align: center;
      align-items: center;
      justify-content: left;
      display: flex;
    }

    .google {
      background-color: #ffffff;
    }

    .facebook {
      background-color: #ffffff;
    }

    .apple {
      background-color: #ffffff;
    }

    .react-tel-input .form-control {
      width: 100%;
      padding: 12px 50px;

      &:focus {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        outline: 0px auto -webkit-focus-ring-color !important;
        outline: none;
        border: none !important;
      }

      &:focus-within {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        outline: 0px auto -webkit-focus-ring-color !important;
        outline: none;
        border: 1px solid $color-primary !important;
        border-radius: 3px;
      }
    }

    .progress-container {
      gap: 5px;
      margin-bottom: 20px;
      display: none;

      @include mdScreen {
        display: flex;
      }

      @include lgScreen() {
        display: flex;
      }
    }

    .progress-bar {
      height: 5px;
      border-radius: 4px;

      &.active {
        background-color: $color-light-green;
      }

      &.inactive {
        background-color: $color-inactive-bar;
      }
    }

    .progress-bar:nth-child(1) {
      width: 50%;
    }

    .progress-bar:nth-child(2) {
      width: 50%;
    }
  }

  &__terms {
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }
    &_agreement {
      max-width: 375px;
      text-align: center;
    }
    &_creator {
      margin-top: 2rem;
      font-size: 1.25rem;
    }
  }
}
