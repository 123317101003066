$font-family-dm: "DM Sans", sans-serif;
$font-family-lato: "Lato", sans-serif;

$color-primary: #00cbc2;
$color-text: #2f2f2f;
$color-text-light: #4f4f4f;
$color-dark-green: #002d2b;
$color-dark-blue: #19345f;
$color-dark-grey: #121212;
$color-white: #ffffff;
$color-yellow: #ffe77b;
$color-success: #30cd57;
$color-success-trans: rgba(48, 205, 87, 0.15);
$color-error: #cd3602;
$color-error-trans: rgba(219, 79, 90, 0.15);
$color-disabled: #e7e9ee;
$color-disabled-2: rgba(144, 151, 165, 0.2);
$color-light-green: #00cbc2;
$color-inactive-bar: #d9d9d9;
$color-black: black;
$color-loader: #00ddd3;
$color-disabled-1: rgba(144, 151, 165, 0.2);
$color-primary-purple: #9A34EB;

$color-input-border: rgba(171, 179, 191, 0.5);

$box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
$box-shadow-form:
  0px 1px 3px rgba(0, 0, 0, 0.1),
  0px 1px 2px rgba(0, 0, 0, 0.06);
